import React, { useState, useRef } from "react";
import GAHoc from "../../GA";
import { Container } from "../../Components/Layout";
import {
  P,
  PGray,
  Pre,
  SectionTitle,
  TextInput,
  VerticalBox,
} from "../../Components/Text";
import memberData from "../../Data/member.json";
import { LinkBtn } from "../../Components/Button";
import { sendPayEmail } from "../../Components/Utils";
import HeaderTitle from "../../Components/Title";

const MeetupJoin = () => {
  const [dispState, setDispState] = useState("name");
  const [name, setName] = useState("");
  const [free, setFree] = useState(false);
  const inputRef = useRef();
  const changeHandler = ({ target: { value } }) => {
    if (Object.keys(memberData).includes(value)) {
      setName(value);
      setDispState("inform");
      if (
        memberData[value].role === "Boogi Standard" ||
        memberData[value].role === "Boogi Plus"
      ) {
        setFree(true);
      }
    }
  };
  const payHandler = (platform) => {
    sendPayEmail(name, "대면모임", 3, platform);
  };
  const StateResponder = ({ state }) => {
    switch (state) {
      case "name":
        return (
          <VerticalBox>
            <SectionTitle>{"사용자의 닉네임을"}</SectionTitle>
            <SectionTitle>{"입력하세요"}</SectionTitle>
            <TextInput
              ref={inputRef}
              type="text"
              onChange={changeHandler}
              placeholder={"닉네임"}
            />
          </VerticalBox>
        );
      case "inform":
        return (
          <VerticalBox>
            <SectionTitle>1월 대면모임</SectionTitle>
            <Pre>{`
            1월 대면모임은 2월 1일 토요일에 3시부터 3시간 동안 진행됩니다.

            모임 장소는 성수동의 공유공간 '성수지앵' 4층입니다.
            `}</Pre>
            <Pre
              style={{ textDecorationLine: "underline", cursor: "pointer" }}
              onClick={() =>
                (window.location.href = "https://naver.me/FwnSgqpu")
              }
            >
              장소 확인하기
            </Pre>
            <P>
              {`
                ${name}님이 담긴 영상 혹은 사진이 독서모임의 마케팅 용도로 사용될 수 있으며
                대면모임 참석 신청을 하시는 경우 이에 동의하신 것으로 간주합니다.
                `}
            </P>
            {!free ? (
              <>
                <P>1월 대면모임 이용료는 1만 5천원입니다</P>
                <LinkBtn onClick={() => payHandler("네이버")}>
                  네이버 페이로 송금
                </LinkBtn>
                <LinkBtn onClick={() => payHandler("카카오")}>
                  카카오 페이로 송금
                </LinkBtn>
                <PGray>
                  간편송금 이용이 어려운 분들은 아래의 계좌번호로 직접
                  입금해주세요.
                </PGray>
                <PGray>(1002-133-822329 우리은행)</PGray>
              </>
            ) : (
              <>
                <LinkBtn onClick={() => payHandler("연간 이용")}>
                  신청하기
                </LinkBtn>
              </>
            )}
            <PGray>대면모임 참석 확인 시 디스코드 메세지가 전송되며</PGray>
            <PGray>최대 1일 소요됩니다.</PGray>
          </VerticalBox>
        );
      default:
        setDispState("name");
        return <VerticalBox>default</VerticalBox>;
    }
  };

  return (
    <GAHoc>
      <HeaderTitle title={"모임 신청 | 1월"} />
      <Container style={{ paddingTop: "0px" }}>
        <StateResponder state={dispState} />
      </Container>
    </GAHoc>
  );
};

export default MeetupJoin;
