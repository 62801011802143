import React from "react";
import { HorizontalBox, P, Pre, VerticalBox } from "../Components/Text";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

const Image = styled.img`
  width: 125px;
  height: 183px;
  max-width: 128px;
  max-height: 183px;
  margin-top: 25px;
`;

const Book = ({ bookInfo }) => {
  if (isMobile) {
    return (
      <VerticalBox>
        <VerticalBox
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <a
            href={bookInfo.link}
            style={{ alignItems: "center", justifyContent: "space-around" }}
          >
            <Image src={bookInfo.img} />
          </a>
          <a
            href={bookInfo.link}
            style={{ alignItems: "center", justifyContent: "space-around" }}
          >
            <Pre>{bookInfo.title}</Pre>
          </a>
          <P>분량: {bookInfo.page}P</P>
        </VerticalBox>
        <Pre style={{ fontSize: "12px" }}>{bookInfo.intro}</Pre>
      </VerticalBox>
    );
  } else {
    return (
      <HorizontalBox>
        <VerticalBox
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <a
            href={bookInfo.link}
            style={{ alignItems: "center", justifyContent: "space-around" }}
          >
            <Image src={bookInfo.img} />
          </a>
          <a
            href={bookInfo.link}
            style={{ alignItems: "center", justifyContent: "space-around" }}
          >
            <Pre>{bookInfo.title}</Pre>
          </a>
          <P>분량: {bookInfo.page}P</P>
        </VerticalBox>
        <Pre style={{ fontSize: "12px" }}>{bookInfo.intro}</Pre>
      </HorizontalBox>
    );
  }
};
export default Book;
