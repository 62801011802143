import React, { useState } from "react";
import GAHoc from "../../GA";
import { Container } from "../../Components/Layout";
import HeaderTitle from "../../Components/Title";
import {
  MainBox,
  P,
  PGray,
  SectionTitle,
  TextInput,
  VerticalBox,
} from "../../Components/Text";
import { SelectBtn } from "../../Components/Button";
import { sendAnnual } from "../../Components/Utils";

const Annual = () => {
  const [nickname, setNickname] = useState("");
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  return (
    <GAHoc>
      <Container>
        <HeaderTitle title="연간 멤버십 등록" />
        <VerticalBox>
          <SectionTitle>{"연간 멤버십 등록"}</SectionTitle>
          <P>{"닉네임을 입력하고 원하는 멤버십을 선택해주세요."}</P>
          <TextInput
            id="nickname"
            key="nickname"
            type="text"
            placeholder="닉네임"
            style={{
              width: "100%",
              textAlign: "start",
              padding: "10px 15px",
            }}
            onChange={(e) => {
              setNickname(e.target.value);
            }}
          />
          <MainBox>
            <SelectBtn
              style={{ width: "100%" }}
              onClick={() => {
                if (nickname === "") {
                  alert("닉네임을 입력해주세요 !");
                } else {
                  sendAnnual(nickname, "Boogi Egg-i");
                }
              }}
            >
              Boogi Egg-i 등록
            </SelectBtn>
          </MainBox>
          <PGray
            onClick={() => setShow1(!show1)}
            style={{ fontSize: "10px", cursor: "pointer" }}
          >
            Boogi Egg-i 설명 보기
          </PGray>
          {show1 ? (
            <>
              <P>{"가격: 50,000원 (기존 60,000원 / 1년)"}</P>
              <P>
                Boogi Egg-i는 온라인 독서모임인 쉘하우스의 기능을 1년 간 할인된
                요금으로 이용할 수 있는 플랜입니다.
              </P>
              <P>
                쉘하우스를 통해 쉘캐스트를 포함한 다양한 콘텐츠를 즐기고, 책
                추천을 받아 사람들과 함께 독서 기록을 남길 수 있습니다.
              </P>
              <P>
                매 달 있는 대면모임에 비용을 지불하고 자유롭게 참석할 수
                있습니다.
              </P>
            </>
          ) : null}
          <MainBox>
            <SelectBtn
              style={{ width: "100%" }}
              onClick={() => {
                if (nickname === "") {
                  alert("닉네임을 입력해주세요 !");
                } else {
                  sendAnnual(nickname, "Boogi Standard");
                }
              }}
            >
              Boogi Standard 등록
            </SelectBtn>
          </MainBox>
          <PGray
            onClick={() => setShow2(!show2)}
            style={{ fontSize: "10px", cursor: "pointer" }}
          >
            Boogi Standard 설명 보기
          </PGray>
          {show2 ? (
            <>
              <P>{"가격: 150,000원 (기존 240,000원 / 1년)"}</P>
              <P>
                Boogi Standard는 온라인 독서모임인 쉘하우스의 기능을 1년 간
                이용하며 모든 대면모임에 추가 비용 없이 참석할 수 있습니다.
              </P>
              <P>
                규칙적인 독서 경험을 위해 독서 기록에 강제성이 부여되며, 꾸준히
                독서하고 기록을 남길 수 있도록 도움받을 수 있습니다.
              </P>
              <P>
                꾸준히 책을 읽으며 기록을 남기고 대면모임에 참석한 Boogi
                Standard 멤버 분들은 차곡차곡 쌓이는 여러분의 독서기록을 보며
                함께 성장합니다.
              </P>
            </>
          ) : null}
        </VerticalBox>
      </Container>
    </GAHoc>
  );
};
export default Annual;
