import emailjs from "@emailjs/browser";

const toNaver = () => {
  window.location.href = `https://new-m.pay.naver.com/remit/qr/inflow?v=1&a=1002133822329&c=020&d=ad7f45ed74d9ad4643a9e991de151956`;
};
const toPaypal = () => {
  window.location.href = "https://www.paypal.com/ncp/payment/LLAG6R72VHCLS";
};
const toPaypalSub = () => {
  window.location.href =
    "https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-5KS63123TG395143UM2Y2X7A";
};
const toThx = () => {
  window.location.href = "/membership/thx";
};

const toKakao = (reqPeriod) => {
  switch (reqPeriod) {
    case 1:
      window.location.href = `https://qr.kakaopay.com/Ej7sKGVNz9c404704`;
      break;
    case 2:
      window.location.href = `https://qr.kakaopay.com/Ej7sKGVNz138806214`;
      break;
    case 3:
      window.location.href = `https://qr.kakaopay.com/Ej7sKGVNz1d4c06210`;
      break;
    case 4:
      window.location.href = `https://qr.kakaopay.com/Ej7sKGVNz271005354`;
      break;
    case 6:
      window.location.href = `https://qr.kakaopay.com/Ej7sKGVNz3a9808390`;
      break;
    case 12:
      window.location.href = `https://qr.kakaopay.com/Ej7sKGVNz61a805244`;
      break;
    case "E":
      window.location.href = `https://qr.kakaopay.com/Ej7sKGVNz61a809178`;
      break;
    case "S":
      window.location.href = `https://qr.kakaopay.com/Ej7sKGVNz124f802922`;
      break;
    case "P":
      window.location.href = `https://qr.kakaopay.com/Ej7sKGVNz249f004543`;
      break;

    default:
      window.location.href = `https://qr.kakaopay.com/Ej7sKGVNz138801645`;
      break;
  }
};
export const curTime = () => {
  const fulltime = new Date();
  const yy = `${fulltime.getFullYear()}`;
  const mm = `${
    fulltime.getMonth() + 1 > 10
      ? (fulltime.getMonth() + 1).toString()
      : "0" + (fulltime.getMonth() + 1).toString()
  }`;
  const dd = `${
    fulltime.getDate() > 10
      ? fulltime.getDate().toString()
      : "0" + fulltime.getDate().toString()
  }`;
  const hh = `${
    fulltime.getHours() > 10
      ? fulltime.getHours().toString()
      : "0" + fulltime.getHours().toString()
  }`;
  const MM = `${
    fulltime.getMinutes() > 10
      ? fulltime.getMinutes().toString()
      : "0" + fulltime.getMinutes().toString()
  }`;
  const ss = `${
    fulltime.getSeconds() > 10
      ? fulltime.getSeconds().toString()
      : "0" + fulltime.getSeconds().toString()
  }`;
  return { yy, mm, dd, hh, MM, ss };
};
export const sendJoinEmail = (nickname, insta, referee) => {
  emailjs
    .send(
      "service_ynqt3v5",
      "template_q0etdx6",
      {
        nickname,
        role: "신규",
        insta,
        referee,
      },
      "sDbK-2vvlIKrTJtdY"
    )
    .then(
      () => console.log("ok 200"),
      (err) => console.log(err)
    );
};
export const sendPayEmail = (nickname, requester, reqPeriod, platform) => {
  let money;
  if (requester === "annual") {
    if (reqPeriod === "E") {
      money = 50000;
    } else if (reqPeriod === "S") {
      money = 150000;
    } else if (reqPeriod === "P") {
      money = 300000;
    }
  } else {
    money = reqPeriod === 12 ? 50000 : reqPeriod * 5000;
  }
  const { yy, mm, dd, hh, MM, ss } = curTime();

  emailjs
    .send(
      "service_ynqt3v5",
      "template_n9u5wfe",
      {
        nickname,
        money,
        requester,
        time: `${yy}-${mm}-${dd}T${hh}:${MM}:${ss}`,
        platform,
      },
      "sDbK-2vvlIKrTJtdY"
    )
    .then(
      () => {
        console.log("ok 200");
        switch (platform) {
          case "네이버":
            toNaver();
            break;
          case "카카오":
            toKakao(reqPeriod);
            break;
          case "페이팔":
            toPaypal();
            break;
          case "페이팔 구독":
            toPaypalSub();
            break;
          case "연간 이용":
            toThx();
            break;
          default:
            break;
        }
      },
      (err) => console.log(err)
    );
};

export const sendBoogimarket = (nickname, title, reason) => {
  emailjs
    .send(
      "service_ynqt3v5",
      "template_n9u5wfe",
      { nickname, title, reason },
      "sDbK-2vvlIKrTJtdY"
    )
    .then(() => (window.location.href = "/"));
};
export const sendRemindApply = (nickname, subject, dateTime) => {
  emailjs
    .send(
      "service_ynqt3v5",
      "template_n9u5wfe",
      { nickname, subject, dateTime },
      "sDbK-2vvlIKrTJtdY"
    )
    .then(() => (window.location.href = "/"));
};
export const sendAnnual = (nickname, role, keyword = "") => {
  const { yy, mm, dd, hh, MM, ss } = curTime();
  emailjs
    .send(
      "service_ynqt3v5",
      "template_q0etdx6",
      {
        nickname,
        role,
        time: `${yy}-${mm}-${dd}T${hh}:${MM}:${ss}`,
        keyword,
      },
      "sDbK-2vvlIKrTJtdY"
    )
    .then(
      () => {
        if (role === "Boogi Egg-i") {
          sendPayEmail(nickname, "annual", "E", "카카오");
        } else if (role === "Boogi Standard") {
          sendPayEmail(nickname, "annual", "S", "카카오");
        }

        if (role === "Boogi Plus") {
          window.location.href = "/membership/thx";
        }
      },
      (err) => console.log(err)
    );
};
export const expired = (month) => {
  const curMonth = new Date().getMonth() + 1;
  return parseInt(month) < curMonth;
};
