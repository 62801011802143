import styled from "styled-components";

export const Title = styled.h1`
  font-size: 24px;
  text-align: center;
  width: 95%;
  color: #3a3a3a;
  font-family: "Nanum Gothic", sans-serif;
`;
export const Info = styled.h1`
  font-size: 32px;
  font-weight: 600;
  margin: 4px 0px;
  text-align: center;
  font-family: "Nanum Gothic", sans-serif;
`;
export const SectionTitle = styled.h1`
  font-size: 24px;
  font-weight: 600;
  margin: 4px 0px;
  text-align: left;
  font-family: "Nanum Gothic", sans-serif;
`;
export const P = styled.p`
  color: #2a2a2a;
  text-align: left;
  line-height: 18px;
  margin: 15px 0px;
  font-size: 14px;
  font-family: "Nanum Gothic", sans-serif;
  font-weight: 600;
`;
export const PGray = styled.p`
  color: #a1a1a1;
  text-align: left;
  line-height: 18px;
  margin: 15px 0px;
  font-size: 14px;
  font-family: "Nanum Gothic", sans-serif;
  font-weight: 600;
`;
export const HorizontalBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  color: #2b2b2b;
  justify-content: space-around;
  align-items: center;
  max-width: 930px;
`;
export const MainBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #2b2b2b;
  justify-content: center;
  align-items: center;
  max-width: 930px;
  padding: 30px 20px;
`;
export const VerticalBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #2b2b2b;
  justify-content: center;
  align-items: left;
  max-width: 930px;
  padding: 30px 20px;
`;
export const Callout = styled.div`
  width: 100%;
  display: flex;
  background-color: #d6d6d6;
  flex-direction: column;
  color: #2b2b2b;
  justify-content: center;
  align-items: left;
  max-width: 930px;
  padding: 30px 20px;
`;
export const Pre = styled.pre`
  color: #4a4a4a;
  text-align: left;
  line-height: 30px;
  margin: 10px 0px;
  font-size: 13px;
  font-family: "Nanum Gothic", sans-serif;
  font-weight: 600;
  white-space: pre-line;
`;
export const ColorTitle = styled(Title)`
  color: #85a977;
`;
export const TextInput = styled.input`
  margin: 10px 0px;
  padding: 10px 0px;
  text-align: center;
  font-size: 18px;
  width: 70%;
  border: none;
  border-bottom: 1px solid #000000;
  background-color: #f7f7f7;
  border-radius: 0;
`;
export const DataInput = (props) => (
  <TextInput
    style={{
      width: "100%",
      textAlign: "start",
      padding: "10px 15px",
    }}
    type="text"
    {...props}
  />
);
