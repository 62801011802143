import React from "react";
import GAHoc from "../../GA";
import { Container } from "../../Components/Layout";
import HeaderTitle from "../../Components/Title";
import { P, SectionTitle, VerticalBox } from "../../Components/Text";
import Logo from "../../Components/Logo";
import { LinkBtn } from "../../Components/Button";

const Thanks = () => {
  const ToHome = () => {
    window.location.href = "/";
  };
  return (
    <GAHoc>
      <Container>
        <HeaderTitle title="감사합니다 | 부기북스" />
        <VerticalBox>
          <Logo src="/logo.png" alt="logo" />
          <SectionTitle>등록이 완료되었습니다.</SectionTitle>
          <P>애정과 관심에 감사드립니다.</P>
          <P>
            등록 완료 이후 안내사항을 디스코드 개인 메세지로 보내드리겠습니다.
          </P>
          <LinkBtn onClick={ToHome}>홈으로 돌아가기</LinkBtn>
        </VerticalBox>
      </Container>
    </GAHoc>
  );
};

export default Thanks;
