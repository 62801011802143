import React, { useState } from "react";
import GAHoc from "../../GA";
import { Container } from "../../Components/Layout";
import HeaderTitle from "../../Components/Title";
import {
  MainBox,
  P,
  PGray,
  SectionTitle,
  TextInput,
  VerticalBox,
} from "../../Components/Text";
import { SelectBtn } from "../../Components/Button";
import { sendAnnual } from "../../Components/Utils";

const BoogiPlus = () => {
  const [nickname, setNickname] = useState("");
  const [keyword, setKeyword] = useState("");
  const [show1, setShow1] = useState(false);

  return (
    <GAHoc>
      <Container>
        <HeaderTitle title="부기 플러스 등록" />
        <VerticalBox>
          <SectionTitle>{"부기 플러스 등록"}</SectionTitle>
          <P>
            {
              "부기플러스 멤버는 쉘하우스에서 한 달 간 진행되는 독서의 키워드를 설정할 수 있습니다."
            }
          </P>
          <P>
            {
              "닉네임과 함께 쉘하우스에서 멤버 분들과 이야기하고 싶은 주제를 한 가지 알려주세요."
            }
          </P>
          <TextInput
            id="nickname"
            key="nickname"
            type="text"
            placeholder="닉네임"
            style={{
              width: "100%",
              textAlign: "start",
              padding: "10px 15px",
            }}
            onChange={(e) => {
              setNickname(e.target.value);
            }}
          />
          <TextInput
            id="keyword"
            key="keyword"
            type="text"
            placeholder="제안하는 키워드"
            style={{
              width: "100%",
              textAlign: "start",
              padding: "10px 15px",
              border: "1px solid black",
              borderRadius: "5px",
              marginTop: "45px",
            }}
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
          />
          <MainBox>
            <SelectBtn
              style={{ width: "100%" }}
              onClick={() => {
                if (nickname === "") {
                  alert("닉네임을 입력해주세요 !");
                } else {
                  sendAnnual(nickname, "Boogi Plus", keyword);
                }
              }}
            >
              제출
            </SelectBtn>
          </MainBox>
          <PGray
            onClick={() => setShow1(!show1)}
            style={{ fontSize: "10px", cursor: "pointer" }}
          >
            Boogi Plus 설명 보기
          </PGray>
          {show1 ? (
            <>
              <P>{"가격: 300,000원 / 1년"}</P>
              <P>
                부기플러스는 부기북스를 프리미엄으로 이용할 수 있는 새로운
                패키지입니다.
              </P>
              <P>
                1. 대면모임에 별도 요금 지불이나, 독서 기록 없이도 자유롭게
                참가할 수 있으며, 인원 제한과 관계 없이 정원 외로 참여
                가능합니다.
              </P>
              <P>
                2. 부기북스에서 진행되는 모든 기획이 부기 플러스 멤버 분들께
                선공지됩니다.
              </P>
              <P>3. 부기 플러스만을 위한 별도의 모임이 연 3회 진행됩니다.</P>
              <P>
                4. 부기 플러스 멤버들이 제안하는 주제는 독서모임을 진행합니다.
              </P>
              <P>
                5. 부기북스에서 준비 중인 여러 기획을 부기플러스 멤버 분들과
                사전 진행합니다.
              </P>
              <P>
                6. 주변 친구들을 자유롭게 초대하실 수 있으며, 부기 플러스 멤버가
                초대한 멤버는 부기 플러스 이용 종료 시까지 무료로 온라인
                쉘하우스를 이용할 수 있습니다.
              </P>
              <P>
                7. 부기플러스 멤버를 위한 특별 프로필 이미지와 역할이
                생성됩니다.
              </P>
            </>
          ) : null}
        </VerticalBox>
      </Container>
    </GAHoc>
  );
};
export default BoogiPlus;
